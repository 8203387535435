body {
  font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue","Helvetica",
  "Arial","PingFang SC","Hiragino Sans GB","Droid Sans Fallback","Microsoft YaHei",
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* transition: opacity .4s ease-in; */
}

#root {
  display: flex;
  flex-direction: column;
  align-items: stretch;
   /* Add some padding to the root element */
}

.dark #root {
  background-color: #0d1117; /* 深色主题下的颜色，你可以根据你的需要修改这个值 */
}

h1 {
  font-size: 4em;
  margin-bottom: 20px; /* Add margin below the title */
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}