.container {
  max-width: 760px;
  box-sizing: border-box;
  padding-left: 16px;
  padding-right: 16px;
  margin: 0 auto;
  zoom: 1;
  display: flex;
  flex-direction: column;
  align-items: left;
  /* gap: 20px;  */
  /* Add some space between each nugget */
  font-family: "Helvetica Neue", "Helvetica", "Arial", "PingFang SC", "Hiragino Sans GB",
    "Droid Sans Fallback", "Microsoft YaHei", sans-serif;
  font: 403 16px/1.75 "Georgia", "Xin Gothic", "PingFang SC", "Hiragino Sans GB",
    "Droid Sans Fallback", "Microsoft YaHei", sans-serif
}

.container .category {
  margin: 0;
}

@media(max-width: 650px) {
  .nauggt a {
    word-break: break-all
  }

  .container {
    padding-left: 18px;
    padding-right: 18px
  }

  .nugget-img {
    width: 100%
  }

  .header-container {
    display: block;
  }

  .header-top-left,
  .header-top-right {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .category-list {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
}

#header {
  margin: 0;
  padding: 20px 0;
  font-size: 24px;
  color: #787879;
  width: auto;
  font-family: "Lucida Grande", "Helvetica Neue", "Helvetica", "Hiragino Sans GB",
    "Droid Sans Fallback", "Microsoft YaHei", sans-serif;
  -webkit-font-smoothing: antialiased
}

.dark #header {
  color: #FFFFFF;
  /* 深色主题下的颜色，你可以根据你的需要修改这个值 */
}

#header a {
  color: #444444;
  font-family: "Avenir Next", "Avenir", "Helvetica Neue", sans-serif;
  text-transform: uppercase;
  text-decoration: none
}

.dark #header a {
  color: #f5f5f5;
  /* 深色主题下的颜色，你可以根据你的需要修改这个值 */
}

#header a:hover {
  opacity: 1;
  color: #002329
}

.dark #header a:hover {
  color: #e6fffb;
  /* 深色主题下的颜色，你可以根据你的需要修改这个值 */
}

.header-container {
  max-width: 860px;
  box-sizing: border-box;
  padding-left: 16px;
  padding-right: 16px;
  margin: 0 auto;
  zoom: 1
}

.header-container:after {
  display: table;
  content: "";
  clear: both
}

#brand {
  float: left;
  display: inline-block;
  margin-bottom: 8px;
  cursor: pointer
}

#brand:hover strong {
  opacity: 1
}

#brand strong {
  opacity: .8;
  color: #444;
  font-size: 24px;
  font-weight: 300;
  vertical-align: top;
  line-height: 30px
}

#brand a:hover {
  border-color: #444
}

#nav {
  float: right;
}

#nav a {
  font-size: 16px;
  margin-right: 15px;
  padding-bottom: 3px;
  border-bottom: 3px solid transparent
}

.rainbow-link {
  position: relative;
}

.rainbow-link:after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: -6px;
  height: 3px;
  background: linear-gradient(to left, red, orange, yellow, green, blue, indigo, violet);
  opacity: 0;
  /* Hide the gradient border initially */
}

.rainbow-link:hover {
  border-color: transparent !important;
  /* Hide the original border on hover */
}

.rainbow-link:hover:after {
  opacity: 1;
  /* Show the gradient border on hover */
}



#nav img {
  padding-bottom: 3px;
}



#nav a:hover {
  border-color: #333333
}

.dark #nav a:hover {
  border-color: #fafafa
}

.nugget {
  text-align: left;
  margin-bottom: 20px;
  /* Add margin below each nugget */
  font-size: 16px;
  font: 400 16px/1.75 "Georgia", "Xin Gothic", "PingFang SC", "Hiragino Sans GB",
    "Droid Sans Fallback", "Microsoft YaHei", sans-serif;
  font-family: "Helvetica Neue", "Helvetica", "Arial", "PingFang SC",
    "Hiragino Sans GB", "Droid Sans Fallback", "Microsoft YaHei", sans-serif;
}

.dark .nugget {
  color: #f0f6fc;
  /* 深色主题下的颜色，你可以根据你的需要修改这个值 */
}

.nugget-id {
  font-family: "Helvetica Neue", "Helvetica", "Arial", "PingFang SC", "Hiragino Sans GB",
    "Droid Sans Fallback", "Microsoft YaHei", sans-serif;
  color: rgba(9, 8, 8, 0.66);
  font: 403 15px/1.75 "Georgia", "Xin Gothic", "PingFang SC", "Hiragino Sans GB",
    "Droid Sans Fallback", "Microsoft YaHei", sans-serif
}

.dark .nugget-id {
  color: #979797;
  /* 深色主题下的颜色，你可以根据你的需要修改这个值 */
}

.nugget-time {
  font: 403 16px/1.75 "Microsoft YaHei", "Georgia", "Xin Gothic", "PingFang SC", "Hiragino Sans GB",
    "Droid Sans Fallback", sans-serif;
  font-weight: bold;
}

.image-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.image-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5px;
  align-items: center;
}

.nugget-img {
  object-fit: cover;
  /* 图片始终填满元素的整个内容区，超出的部分会被裁剪掉，并且图片始终保持原始的长宽比 */
  justify-content: center;
  border-radius: 5px;
  box-shadow: 4px 0 6px rgba(27, 27, 27, 0.5);
}

.nugget-img-alt {
  margin-top: 2px;
  font-size: 15px;
  color: #595959;
  text-align: center;
  word-break: break-all;
  /* 修改这一行来实现在单词之间的空格处换行 */
}

.nugget-video {
  width: 100%;
  height: auto;
  margin-top: 16px;
  justify-content: center;
  /* 在水平方向上居中 */
  border-radius: 8px;
  /* 设置图片容器的圆角，根据需要调整 */
  box-shadow: 4px 0 6px rgba(27, 27, 27, 0.5);
  /* 阴影效果，根据需要调整 */
}

.nugget-video :hover {
  border-radius: 8px;
  /* 设置图片容器的圆角，根据需要调整 */
  box-shadow: 7px 7px 7px rgba(3, 16, 23, 0.477);
  /* 阴影效果，根据需要调整 */
}

.dark .nugget-video :hover {
  color: #FFFFFFD9;
  /* 深色主题下的颜色，你可以根据你的需要修改这个值 */
}

.nugget-video-alt {
  margin-top: 2px;
  font-size: 15px;
  color: #999;
  text-align: center;
}

.nugget-audio {
  margin-top: 16px;
}

.nugget-createTime {
  color: #595959;
  font-size: 10px;
  font-family: -apple-system, BlinkMacSystemFont, Helvetica Neue,
    PingFang SC, Hiragino Sans GB, Droid Sans Fallback, Microsoft YaHei, sans-serif
}

.dark .nugget-createTime {
  color: #ffffff77;
  /* 深色主题下的颜色，你可以根据你的需要修改这个值 */
}

/* .nugget-remark {
  color: rgba(0,0,0,.5);
  font-size: 13px;
  font-weight: bold;
} */

/* 图片容器样式 */
.figure-container {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 75%;
  /* 控制图片容器的高度，可以根据需要调整，这里设置成4:3的比例 */
  overflow: hidden;
}


.foot-pagination {
  margin-top: 30px;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* .dark .foot-pagination {
  filter: invert(100%);
} */

/* .custom-back-top {
  height: 40px;
  width: 40px;
  line-height: 40px;
  border-radius: 6px;
  background-color: #262626;
  color: #fff;
  text-align: center;
  font-size: 14px;
  font-weight: bolder;
}

.dark .custom-back-top {
  color:#000;
  background-color: #f0f0f0;
} */
/* background-color: #08979c; */

#footer {
  padding: 10px 0 0;
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center;
  color: #999;
  font-size: 12px;
  font-family: "Optima", "Helvetica Neue", sans-serif
}

#return-to-home {
  padding: 20px 0;
  text-align: center;
  font-size: 24px;
  font-family: "Optima", "Helvetica Neue", sans-serif;
  font-weight: bold;
  color: #8c8c8c;
}

#return-to-home :hover {
  font-size: 25px;
  color: #262626;
}

.dark #return-to-home :hover {
  color: #f0f0f0;
}

/* 上一页下一页样式 */
.lr-button-container {
  display: flex;
  justify-content: space-between;
}

/* 按钮样式 */
.lr-button {
  color: #0c0c0c;
  border: none;
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.dark .lr-button {
  color: #FFFFFF;
}

.lr-button:hover {
  color: #2980b9;
}

.dark .lr-button:hover {
  color: #1677ff;
}

.lr-button:disabled {
  color: #bdc3c7;
  cursor: not-allowed;
}

.dark .lr-button:disabled {
  color: #979797;
}

/* 图标样式 */
.lr-icon {
  font-size: 20px;
}

.nav_header {
  font-size: 24px;
  font-family: "Georgia", "Xin Gothic", "PingFang SC",
    "Hiragino Sans GB", "Droid Sans Fallback", "Microsoft YaHei", sans-serif;
}


/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
} */

.App-link {
  color: #61dafb;
}

.per-page-title {
  font-family: 'Arial', sans-serif;
  /* Replace with your preferred font */
  font-size: 24px;
  /* Adjust size as needed */
  text-align: center;
  /* Center the text */
  margin-bottom: auto;
}

.dark .per-page-title {
  color: #FFFFFF;
}

.post-remark {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  /* Replace with your preferred font */
  font-size: 16px;
  /* Adjust size as needed */
  text-align: center;
  /* Center the text */
  margin-bottom: 40px;
  padding: 10px;
}

.dark .post-remark {
  color: #ffffff;
  /* 深色主题下的颜色，你可以根据你的需要修改这个值 */
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}